/* eslint-disable react/style-prop-object */
import React from "react";

export default function HidePassword() {
	return (
		<svg
			height="100%"
			fillRule='evenodd'
			clipRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit={1.41421}
			version="1.1"
			viewBox="0 0 128 128"
			width="100%"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				height="128"
				id="Hide"
				fill='none'
				width="128"
				x="0.713"
				y="0"
			/>
			<path
				d="M73.562,52.323l37.737,-37.737c0.413,-0.361 0.623,-0.673 1.754,-0.557c1.441,0.351 2.162,2.138 1.074,3.385l-37.793,37.793c3.084,4.086 4.332,9.591 2.199,14.631c-3.089,7.296 -12.586,11.11 -19.874,7.888c-1.086,-0.48 -2.092,-1.101 -3.008,-1.836l-37.524,37.524c-0.91,0.794 -1.353,0.704 -2.019,0.492c-1.291,-0.409 -1.774,-2.214 -0.809,-3.32l37.601,-37.601c-3.203,-4.425 -4.145,-10.583 -1.626,-15.651c2.519,-5.067 7.563,-8.372 13.634,-8.333c3.191,0.062 6.2,1.321 8.654,3.322Zm23.876,-15.376c1.054,0.208 1.1,0.376 1.571,0.573c12.276,5.169 24.115,13.554 29.537,25.529c0,0 0.295,1.184 -0.415,2.565c-11.251,21.891 -39.324,32.584 -64.921,32.382c-6.675,-0.053 -13.382,-0.554 -19.893,-1.983c0,0 -1.814,-0.365 -2.095,-1.658c-0.325,-1.493 0.781,-2.726 3.243,-2.186c28.156,6.04 60.74,-0.832 77.094,-23.545c1.084,-1.505 2.089,-3.072 2.951,-4.715l0.003,-0.008c-5.595,-10.713 -16.525,-18.406 -27.821,-23.01c0,0 -1.443,-0.625 -1.513,-1.848c-0.067,-1.177 0.994,-2.183 2.259,-2.096Zm-32.608,-6.946c7.359,0.039 14.716,0.785 21.882,2.527c0.743,0.181 1.747,0.837 1.824,1.87c0.106,1.442 -0.868,2.466 -3.36,1.876c-28.03,-6.527 -60.437,-0.614 -77.142,22.647c-1.133,1.577 -2.18,3.223 -3.064,4.952c5.815,10.606 15.977,18.335 27.016,23.228c0,0 1.872,1.468 0.834,2.967c-0.711,1.027 -2.163,0.831 -3.465,0.235c-11.78,-5.438 -22.699,-13.757 -28.401,-25.489c0,0 -0.33,-1.198 0.368,-2.592c10.696,-21.352 37.792,-32.269 63.508,-32.221Zm8.947,27.764l-15.163,15.162c1.762,1.287 3.871,2.072 6.099,2.073c7.485,0.002 13.672,-9.144 9.591,-16.387c-0.164,-0.292 -0.34,-0.575 -0.527,-0.848Zm-2.819,-2.839c-1.8,-1.242 -3.988,-1.941 -6.387,-1.925c-5.076,0.096 -9.786,4.018 -10.686,9.051c-0.502,2.808 0.308,5.686 1.934,8.014l15.139,-15.14Z"
				fillRule='nonzero'
			/>
		</svg>
	);
}
